<template>
  <div>
    <molecules-custom-dialog
      :show="showRemove"
      title="Apakah Anda Yakin?"
      :caption="`Anda akan menghapus testimonial?`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => {
            showRemove = null
          },
        },
        {
          title: 'Iya Hapus',
          override: 'red--text',
          full: false,
          exec: () => {
            removeTesti(showRemove)
            showRemove = null
          },
        },
      ]"
    />
    <!-- <v-row>
        <v-col cols="12" md="4">
          <molecules-summary-card
            icon="account-multiple"
            :title="`${$idCurrency($store.state.cases.kerugian)} Total Kerugian`"
            :desc="`Tanggal ${$moment().format('DD MMMM YYYY')}`"
            :loading="reportLoading"
          />
        </v-col>
        <v-col cols="12" md="4">
          <molecules-summary-card
            icon="account-multiple-plus"
            color="green"
            :title="`${$idCurrency(
              $store.state.cases.pengembalian
            )} Total Pengembalian`"
            :desc="`Tanggal ${$moment().format('DD MMMM YYYY')}`"
            :loading="reportLoading"
          />
        </v-col>
        <v-col cols="12" md="4">
          <molecules-summary-card
            icon="account-clock"
            color="orange"
            :title="`${$idCurrency($store.state.cases.hutang)} Total Hutang`"
            :desc="`Tanggal ${$moment().format('DD MMMM YYYY')}`"
            :loading="reportLoading"
          />
        </v-col>
      </v-row> -->

    <v-form @submit.prevent="loadData" id="filter">
      <v-row justify="space-between" align="center">
        <v-col cols="12" md="8">
          <atoms-text-field
            label="Cari testi, ketikkan judul dll.."
            v-model.trim="term"
            :noLabel="true"
            :appendAction="
              () => {
                term = ''
                loadData()
              }
            "
            appendIcon="mdi-close"
          />
        </v-col>
        <v-col cols="12" md="2" class="d-flex justifyx-space-between">
          <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-magnify</v-icon>Cari Testi</atoms-button
          >
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-space-between">
          <atoms-button @click="showSubmit = true" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-plus</v-icon>Tambah Testi</atoms-button
          >
        </v-col>
        <v-col cols="12" lg="2">
          <!-- <v-row align="center" dense no-gutters>
            <v-col cols="12" md="2"> -->
          <atoms-button
            class="primary"
            style="width: 100%"
            @click="
              () => {
                sort = sort === constant[0] ? constant[1] : constant[0]
                getTestimonial()
              }
            "
            ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
            >Sort: {{ sort }}</atoms-button
          >
          <!-- </v-col> -->
          <!-- <v-col
              class="grow d-flex justify-center justify-md-start ml-md-4 align-center"
            >
              <v-checkbox
                v-model="isUseDatePromo"
                hide-spin-buttons
                @change="getTestimonial"
              /><atoms-text>Tampilkan hanya yang berlaku</atoms-text>
            </v-col> -->
          <!-- </v-row> -->
        </v-col>
        <!-- 
        <v-col cols="12" md="4">
          <v-combobox
            class="rounded-lg"
            placeholder="Filters"
            prepend-inner-icon="mdi-filter-variant"
            :items="filters.map((x) => x.title)"
            v-model="filter"
            @change="loadData"
            hide-details
            multiple
            dense
            solo
            flat
            required
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
        /></v-col> -->
      </v-row>
    </v-form>

    <div class="mt-4">
      <div v-if="mainLoading">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="list-item-avatar"
          class="accent rounded-lg mb-2 pa-2"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </div>
      <div v-else>
        <div v-if="$store.state.testimonial.data && $store.state.testimonial.data.length > 0">
          <!-- <atoms-text class="pa-2"
              ><v-icon>mdi-magnify</v-icon>Hasil Pencarian, {{ items.count }} data
              ditemukan</atoms-text
            > -->
          <div
            v-for="({ avatar, title, caption, description, ...rest }, i) in $store.state.testimonial.data || []"
            :key="i"
            :class="['accent', 'rounded-lg pa-4 mb-2']"
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
          >
            <v-row align="center">
              <v-col class="shrink">
                <atoms-image :width="150" :enableZoom="true" :src="avatar" class="rounded-lg" />
              </v-col>
              <v-col cols="8" lg="4">
                <atoms-text :auto="true" class="font-weight-bold">{{
                  $toCapitalize(title || 'Judul tidak dicantumkan')
                }}</atoms-text>
                <atoms-text class="primary--text">{{ caption || '-' }}</atoms-text>
              </v-col>
              <v-col align="start">
                <atoms-text class="font-weight-bold"
                  >Dibuat pada
                  {{ $moment(rest._createdDate).format('dddd, DD MMMM YYYY') }}
                </atoms-text>
                <atoms-text v-if="rest.createdByData"
                  >oleh
                  {{ rest.createdByData.nickname || '-' }}
                </atoms-text>
              </v-col>
              <!-- <v-col align="start">
                  
                </v-col> -->
              <v-col class="shrink">
                <v-menu offset-x class="rounded-xl">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list nav dense elevation="0" class="text-center rounded-lg">
                    <v-list-item link @click="showDetail = rest._id" class="rounded-lg px-4 text-center">
                      <v-icon left>mdi-view-list-outline</v-icon>
                      <v-list-item-title class="px-2">Detail</v-list-item-title>
                    </v-list-item>
                    <v-list-item link @click="showEdit = rest._id" class="rounded-lg px-4 text-center">
                      <v-icon left>mdi-pencil</v-icon>
                      <v-list-item-title class="px-2">Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      link
                      v-if="$store.state.user.roles.includes($roles.Super_Admin)"
                      @click="
                        showRemove = {
                          avatar,
                          title,
                          caption,
                          description,
                          ...rest,
                        }
                      "
                      class="rounded-lg px-4 text-center red white--text"
                    >
                      <v-icon left dark>mdi-delete</v-icon>
                      <v-list-item-title class="px-2">Hapus</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="12">
                <atoms-text>Deskripsi : {{ description || '-' }}</atoms-text>
              </v-col>
            </v-row>
          </div>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination class="my-4" v-model="page" :length="$store.state.testimonial.pagesLength"></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </div>
        <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8">
          <div class="text-center">
            <atoms-image :width="250" src="/assets/missing.svg" />
            <atoms-title :h3="true" class="mt-6">Tidak ada testimoni yang ditemukan!</atoms-title>
          </div>
        </molecules-wrapper>
      </div>
    </div>
    <v-slide-x-reverse-transition>
      <organism-testimonial-submit
        v-if="showSubmit || showEdit"
        :id="showEdit"
        @close="
          () => {
            showSubmit = false
            showEdit = null
          }
        "
      />
    </v-slide-x-reverse-transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      term: '',
      page: 1,
      limit: 10,
      showDetail: null,
      showSubmit: false,
      showRemove: null,
      showEdit: null,
      reportLoading: false,
      mainLoading: false,
      sort: '',
      constant: ['Desc', 'Asc'],
    }
  },
  watch: {
    page: {
      handler() {
        this.getTestimonial()
      },
      deep: true,
    },
  },
  async mounted() {
    this.sort = this.constant[0]
    this.loadData()
  },
  methods: {
    loadData() {
      this.page = 1
      this.getTestimonial()
      this.$vuetify.goTo(0)
    },
    async removeTesti(payload) {
      this.$store.commit('SET', { loading: true })
      try {
        if (payload?._id) {
          await this.$store.dispatch('testimonial/remove', payload?._id)
          this.$showDialog({
            title: 'Berhasil',
            body: `Testimonial berhasil dihapus!`,
          })
          this.loadData()
        }
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    async getTestimonial() {
      this.mainLoading = true
      try {
        await this.$store.dispatch('testimonial/getAll', {
          custom: {
            title: { $regex: this.term || '', $options: 'i' },
            pipeline: [
              {
                $sort: {
                  _createdDate: this.sort === this.constant[0] ? -1 : 1,
                },
              },
              ...this.$store.state.testimonial?.defaultPipeline,
            ],
          },
          page: this.page,
          limit: this.limit,
        })
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.mainLoading = false
      }
    },
  },
}
</script>
