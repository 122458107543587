var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('molecules-custom-dialog',{attrs:{"show":_vm.showRemove,"title":"Apakah Anda Yakin?","caption":`Anda akan menghapus testimonial?`,"items":[
      {
        title: 'Batalkan',
        full: false,
        exec: () => {
          _vm.showRemove = null
        },
      },
      {
        title: 'Iya Hapus',
        override: 'red--text',
        full: false,
        exec: () => {
          _vm.removeTesti(_vm.showRemove)
          _vm.showRemove = null
        },
      },
    ]}}),_c('v-form',{attrs:{"id":"filter"},on:{"submit":function($event){$event.preventDefault();return _vm.loadData.apply(null, arguments)}}},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('atoms-text-field',{attrs:{"label":"Cari testi, ketikkan judul dll..","noLabel":true,"appendAction":() => {
              _vm.term = ''
              _vm.loadData()
            },"appendIcon":"mdi-close"},model:{value:(_vm.term),callback:function ($$v) {_vm.term=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"term"}})],1),_c('v-col',{staticClass:"d-flex justifyx-space-between",attrs:{"cols":"12","md":"2"}},[_c('atoms-button',{staticClass:"primary lighten-5 primary--text",staticStyle:{"width":"100%"},attrs:{"type":"submit"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v("Cari Testi")],1)],1),_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12","md":"2"}},[_c('atoms-button',{staticClass:"primary lighten-5 primary--text",staticStyle:{"width":"100%"},on:{"click":function($event){_vm.showSubmit = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Tambah Testi")],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('atoms-button',{staticClass:"primary",staticStyle:{"width":"100%"},on:{"click":() => {
              _vm.sort = _vm.sort === _vm.constant[0] ? _vm.constant[1] : _vm.constant[0]
              _vm.getTestimonial()
            }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-variant-"+_vm._s(_vm.sort === _vm.constant[0] ? 'minus' : 'plus'))]),_vm._v("Sort: "+_vm._s(_vm.sort))],1)],1)],1)],1),_c('div',{staticClass:"mt-4"},[(_vm.mainLoading)?_c('div',_vm._l((5),function(i){return _c('v-skeleton-loader',{key:i,staticClass:"accent rounded-lg mb-2 pa-2",style:({
          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
        }),attrs:{"type":"list-item-avatar"}})}),1):_c('div',[(_vm.$store.state.testimonial.data && _vm.$store.state.testimonial.data.length > 0)?_c('div',[_vm._l((_vm.$store.state.testimonial.data || []),function({ avatar, title, caption, description, ...rest },i){return _c('div',{key:i,class:['accent', 'rounded-lg pa-4 mb-2'],style:({
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          })},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"shrink"},[_c('atoms-image',{staticClass:"rounded-lg",attrs:{"width":150,"enableZoom":true,"src":avatar}})],1),_c('v-col',{attrs:{"cols":"8","lg":"4"}},[_c('atoms-text',{staticClass:"font-weight-bold",attrs:{"auto":true}},[_vm._v(_vm._s(_vm.$toCapitalize(title || 'Judul tidak dicantumkan')))]),_c('atoms-text',{staticClass:"primary--text"},[_vm._v(_vm._s(caption || '-'))])],1),_c('v-col',{attrs:{"align":"start"}},[_c('atoms-text',{staticClass:"font-weight-bold"},[_vm._v("Dibuat pada "+_vm._s(_vm.$moment(rest._createdDate).format('dddd, DD MMMM YYYY'))+" ")]),(rest.createdByData)?_c('atoms-text',[_vm._v("oleh "+_vm._s(rest.createdByData.nickname || '-')+" ")]):_vm._e()],1),_c('v-col',{staticClass:"shrink"},[_c('v-menu',{staticClass:"rounded-xl",attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"text-center rounded-lg",attrs:{"nav":"","dense":"","elevation":"0"}},[_c('v-list-item',{staticClass:"rounded-lg px-4 text-center",attrs:{"link":""},on:{"click":function($event){_vm.showDetail = rest._id}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-view-list-outline")]),_c('v-list-item-title',{staticClass:"px-2"},[_vm._v("Detail")])],1),_c('v-list-item',{staticClass:"rounded-lg px-4 text-center",attrs:{"link":""},on:{"click":function($event){_vm.showEdit = rest._id}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_c('v-list-item-title',{staticClass:"px-2"},[_vm._v("Edit")])],1),(_vm.$store.state.user.roles.includes(_vm.$roles.Super_Admin))?_c('v-list-item',{staticClass:"rounded-lg px-4 text-center red white--text",attrs:{"link":""},on:{"click":function($event){_vm.showRemove = {
                        avatar,
                        title,
                        caption,
                        description,
                        ...rest,
                      }}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-delete")]),_c('v-list-item-title',{staticClass:"px-2"},[_vm._v("Hapus")])],1):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('atoms-text',[_vm._v("Deskripsi : "+_vm._s(description || '-'))])],1)],1)],1)}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-container',{staticClass:"max-width"},[_c('v-pagination',{staticClass:"my-4",attrs:{"length":_vm.$store.state.testimonial.pagesLength},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],2):_c('molecules-wrapper',{staticClass:"d-flex justify-center align-center my-8 py-8"},[_c('div',{staticClass:"text-center"},[_c('atoms-image',{attrs:{"width":250,"src":"/assets/missing.svg"}}),_c('atoms-title',{staticClass:"mt-6",attrs:{"h3":true}},[_vm._v("Tidak ada testimoni yang ditemukan!")])],1)])],1)]),_c('v-slide-x-reverse-transition',[(_vm.showSubmit || _vm.showEdit)?_c('organism-testimonial-submit',{attrs:{"id":_vm.showEdit},on:{"close":() => {
          _vm.showSubmit = false
          _vm.showEdit = null
        }}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }